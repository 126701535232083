import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import Container from "../../components/Container/Container";
import { setDetailFromCargo } from "../../store/detail/actions";
import { useDispatch } from "react-redux";

const Home = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // get queries
  const token = searchParams.get("token");
  const userCode = searchParams.get("user");
  const data = searchParams.get("data");
  const type = searchParams.get("type");

  useEffect(() => {
    if (token && userCode) {
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("userCode", userCode);

      if (data) {
        // replace whitespace with '+'
        const replace = data.replace(/\s/g, "+");

        // decode base64
        const decode = window.atob(replace);

        // parse to json
        const parse = JSON.parse(decode);
        dispatch(setDetailFromCargo(parse));

        setTimeout(() => {
          navigate("/klaim");
        }, 100);
      } else if (type && type === "list") {
        setTimeout(() => {
          navigate("/riwayat");
        }, 100);
      } else {
        setTimeout(() => {
          // navigate("/404")
          navigate("/klaim/check");
        }, 100);
      }
    } else {
      setTimeout(() => {
        navigate("/404");
      }, 100);
    }
  }, [data, dispatch, navigate, token, type, userCode]);

  return (
    <Container>
      <Spinner />
    </Container>
  );
};

export default Home;
